<template>
  <div>
    <!-- :items="answers"
            :columns="answerCol" -->
    <!-- {{ answers }} -->
    <ek-table :items="answers" :columns="columnsAnswer" @details="goToDetails">
      <template slot="items.dataCreated" slot-scope="{ value }">
        {{ new Date(value).toLocaleDateString() }}
      </template>
    </ek-table>
  </div>
</template>

<script>
import { mapState } from "vuex";


export default {
  data() {
    return {
      questions: ({ questionsAnswerer }) => questionsAnswerer.incommingQuestions,
      columnsAnswer: [
        {
          label: " السؤال ",
          field: "question",
        },
        {
          label: " الإجابة ",
          field: "answer",
        },
        {
          label: " اسم المادة",
          field: "subjectName",
        },

        {
          label: " تاريخ ",
          field: "dataCreated",
        },
        {
          label: " تفاصيل",
          field: "details",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      answers: ({ questionsAnswerer }) => questionsAnswerer.answerQuestions,
    }),
  },
  methods: {
    goToDetails({ row }) {
      this.$router.push(
        `/admin/questionAnswerDashBoard/incommingQuestion/${row.questionId}`
      );
      // console.log(row.questionId);
      // console.log();
      // this.$store.commit("getStudentQuestion", e);
    },
  },
  created() {
    this.$store.dispatch("getAllAnswer");
  },
};
</script>

<style></style>
